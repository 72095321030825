/* eslint-disable global-require */
import React, { useContext } from 'react';
import { Metadata } from '@latitude/metadata';

import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import { Accordion } from '@latitude/accordion';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  BUTTON_STYLE,
} from '@latitude/core/utils/constants';

import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionButtons,
  PaymentOptionNote,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionHeading
} from '../../../components/PaymentOptions/PaymentOptions';

import Layout from '@/components/layout';
import { StickyNavigation } from '@latitude/sticky-navigation';

import { Heading4 } from '@latitude/heading';
import { Box } from '@latitude/box';
import SvgInline from '@latitude/svg-inline';
import HowToApply from '@latitude/how-to-apply';
import { ListNumbered } from '@latitude/list-numbered';
import { List, ListItem } from '@latitude/list';
import { FeaturesSlider } from '@latitude/features-slider';

import Text from '@/components/Text/Text';
import { Strong } from '@/components/Text/Bold';

import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';

import PageData from '../../../data/pages/credit-cards/twenty-eight-degree.json';

import { ImportantInformation } from '@latitude/important-information';

import StandaloneText from '@/components/lab-components/StandaloneText';
import PromoBanner from '@/components/lab-components/PromoBanner';
import { CARD_CODE, CARD_DATA } from '@/data/pages/credit-cards/card-offer';
import { AnalyticsContext } from 'latitude-analytics';


const KEEP_EXPLORING_INFO = [
  {
    title: 'Find your inspiration',
    thumbnail: 'find-your-inspiration.webp',
    path:
      'https://www.latitudefinancial.com.au/credit-cards/28-degrees/travel-inspiration/'
  },
  {
    title: 'Plan your trip',
    thumbnail: 'plan-your-trip.webp',
    path:
      'https://www.latitudefinancial.com.au/credit-cards/28-degrees/travel-inspiration/'
  },
  {
    title: 'Calculate your savings',
    thumbnail: 'calculate-your-savings.webp',
    path:
      'https://www.latitudefinancial.com.au/credit-cards/28-degrees/travel-inspiration/'
  }
];

// Card data array
const cardData = CARD_DATA.filter((card) => card.code !== CARD_CODE['28D'])

const TwoFeeWaiverPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: '28-degrees',
    page_pageType: 'product-details-page',
    page_siteSection: 'cards',
    page_siteSubsection: '28-degrees',
    product_productid: ["CCAU28D"]
  };

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        <Metadata
          title={'2 Fee Waiver | 28° Platinum Mastercard Credit Card'}
          description={'2 Fee Waiver'}
          keywords="2 Fee Waiver"
          noIndex={true}
        />
        
        {state?.heroBannerData?.[0] && (
          <HeroBanner {...state?.heroBannerData[0]} />
        )}

        <div
          className="d-none d-lg-block"
          css={`
            position: relative;
            z-index: 10;
          `}
        >
          <StickyNavigation
            items={[
              {
                anchor: 'why-us',
                label: 'Why us?'
              },
              {
                anchor: 'latest-offers',
                label: 'Offers'
              },
              {
                anchor: 'rates',
                label: 'Rates & Fees'
              },
              {
                anchor: 'how-to-apply',
                label: 'How to apply'
              },
              {
                anchor: 'payment-options',
                label: 'Payment Options'
              },
              {
                anchor: 'faq',
                label: 'FAQ'
              }
            ]}
            ctaText="Get Rate Estimate"
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        {state?.featureSliderData?.[0] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[0].heading}
            heading={state?.featureSliderData?.[0].heading}
            id="waiver-rolling"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[0].featureCards}
            subheading={state?.featureSliderData?.[0].description}
            contentAlign={state?.featureSliderData?.[0].contentAlign}
            backgroundColor={COLOR.WHITE}
            css={`
              && {
                padding: 40px 15px 0;
              }
              .lfs-box-responsive {
                @media (min-width: 1200px) {
                  max-width: 780px;
                }
              }
              .lfs-card {
                align-items: flex-start;
              }
              .lfs-card-text {
                align-items: flex-start;
              }
              .lfs-card-body > p {
                text-align: left;
              }
            `}
          />
        )}

        {state?.featureSliderData?.[1] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[1].heading}
            heading={state?.featureSliderData?.[1].heading}
            id="why-us"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[1].featureCards}
            subheading={state?.featureSliderData?.[1].description}
            contentAlign={state?.featureSliderData?.[1].contentAlign}
            backgroundColor={COLOR.WHITE}
            css={`
              && {
                padding: 40px 15px 0;
              }
            `}
          />
        )}

        {state?.standaloneTextData?.[0] && (
          <StandaloneText 
            text={state?.standaloneTextData?.[0].text}
            align={state?.standaloneTextData?.[0].align}
            background={state?.standaloneTextData?.[0].background}
            styles={`
              ul {
                padding-left: 10px;
                margin: 0;
              }
              ul p {
                margin-bottom: 5px;
                padding: 0;
              }
              p {
                font-size: 16px;
                margin-top: 0;
                padding-left: 9px;
              }
              h5 {
                margin-top: 0;
              }
            `}
          />
        )}

        {state?.ratesAndFeesData?.[0] && (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        )}

        <HowToApply
          id="how-to-apply"
          box1={
            <div
              css={`
                .list-numbered li {
                  align-items: flex-start;
                  color: ${COLOR.BLACK};
                }
              `}
            >
              <Heading4
                marginBottom={MARGIN.M16}
                isResponsive={false}
                color={COLOR.BLACK}
              >
                How to apply
              </Heading4>
              <ListNumbered
                data={[
                  `<span>
                      <strong style="font-weight:500;">Apply online</strong> <br /> You can check your
                      eligibility and apply in 10 minutes.</span>
                    </span>`,
                  `<span>
                      <strong style="font-weight:500;">Wait a minute</strong> <br /> 60 seconds is all it takes for us to respond to your application.</span>
                    </span>`,
                  `<span>
                      <strong style="font-weight:500;">Ready, set, shop</strong> <br /> Simply activate your card when it arrives to start shopping and reaping the rewards.</span>
                    </span>`
                ]}
                viewStyle="compact"
                separator="none"
                counterBgColor={COLOR.BLUE_DEEP}
              />
            </div>
          }
          box2={
            <div
              css={`
                && {
                  p {
                    color: ${COLOR.BLACK};
                  }
                  ul {
                    color: ${COLOR.BLACK};
                    margin-left: 24px;
                    li:before {
                      line-height: 0.85em;
                    }
                  }
                }
              `}
            >
              <Heading4
                marginBottom={MARGIN.M16}
                isResponsive={false}
                color={COLOR.BLACK}
              >
                Eligibility
              </Heading4>
              <Text marginBottom={MARGIN.M8} fontWeight={500}>
                You must:
              </Text>
              <List>
                <ListItem>Be 18 years or over</ListItem>
                <ListItem>Be a permanent Australian resident</ListItem>
              </List>
              <br />
              <Text marginBottom={MARGIN.M8} fontWeight={500}>
                Able to supply
              </Text>
              <List>
                <ListItem>
                  A copy of your driver’s licence, passport or proof of age card
                </ListItem>
                <ListItem>Details of your income, assets and debts</ListItem>
                <ListItem>Employer’s name and contact details</ListItem>
              </List>
            </div>
          }
        />

        {state?.promoBannerData?.[0] && (
          <PromoBanner {...state?.promoBannerData[0]} />
        )}

        <Section
          heading="Payment Options"
          id="payment-options"
          className="section--payment-options"
          css={`
            && {
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-bottom: 72px;
              }
            }
          `}
        >
          <PaymentOptions
            data={[
              {
                id: 'latitude-app',
                title: 'Latitude App',
                titleHtml: 'Latitude App',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-latitude-app" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Make repayments in just a few taps with our mobile app.
                      </PaymentOptionDescription>
                      <PaymentOptionImages>
                        <Link
                          href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-itunes-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-app-store.svg')
                                .default
                            }
                            height="48px"
                            alt="app store"
                          />
                        </Link>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-googleplay-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-google-play.svg')
                                .default
                            }
                            height="48px"
                            alt="play store"
                          />
                        </Link>
                      </PaymentOptionImages>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Get the app</Strong>
                          <Text fontSize="14px">
                            Simply download the Latitude App from the App Store
                            or Google Play
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Create a Latitude ID</Strong>
                          <Text fontSize="14px">
                            Open the app and create your Latitude ID (this is
                            different to your Latitude Service Centre login).
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Link your card</Strong>
                          <Text fontSize="14px">
                            Once your credit cards are linked, you’ll be able to
                            make payments via bank transfer with your BSB and
                            Account Number (Australia only).
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionNote>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> Please be aware that bank
                          transfer payments can take three business days to
                          clear and you can only make one payment to your card
                          account every five days.
                        </Text>
                      </PaymentOptionNote>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via direct
                          bank transfer on the Latitude App.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'online-service-centre',
                title: 'Latitude Service Centre',
                titleHtml: 'Latitude Service Centre',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-online" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        Latitude Service Centre
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        One of the quickest &amp; easiest way to make
                        repayments.
                      </PaymentOptionDescription>
                      <PaymentOptionButtons css="justify-content:center;">
                        <Link
                          target="_blank"
                          button="secondary"
                          href="https://servicecentre.latitudefinancial.com.au/login"
                          data-trackid="payment-options-service-center-login-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Login
                        </Link>
                        <Link
                          target="_blank"
                          button="tertiary"
                          href="https://servicecentre.latitudefinancial.com.au/register"
                          data-trackid="payment-options-service-center-register-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Register
                        </Link>
                      </PaymentOptionButtons>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Login to the Latitude Service Centre</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Click ‘Pay My Account’</Strong>
                          <Text fontSize="14px">
                            Then enter your bank details.
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                      <PaymentOptionHeading>Registering</PaymentOptionHeading>
                      <Text fontSize="14px">
                        Registering is easy and only takes a few minutes, just
                        make sure you have your card or 16-digit account number
                        on hand.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> Please be aware that payments can
                        take 3 to 5 business days to clear.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via the
                          Latitude Service Centre.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'bpay',
                title: 'BPAY',
                titleHtml:
                  'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-bpay" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        BPAY<sup>®</sup>
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Available 24 hours a day, seven days a week.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Quote the Biller code</Strong>
                          <Text fontSize="14px">
                            Latitude 28° Global Platinum Mastercard Biller Code
                            (150615)
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Your account number</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>The amount you wish to pay</Strong>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> If you’re paying by BPAY on your
                        due date, you must complete the transaction before your
                        bank’s cut off time, otherwise the transaction may be
                        processed on the following day. Some BPAY payments can
                        take up to three days to appear on your account.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>Fees</Strong>
                        <Text fontSize="14px">
                          A payment handling fee of $1.95 applies for all
                          payments made via BPAY<sup>&reg;</sup> to pay your
                          account.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'direct-debit',
                title: 'Direct Debit',
                titleHtml: 'Direct Debit',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-direct-debit" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Easily make recurring repayments.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn longColumn>
                      <Text>
                        The easiest (and quickest) way to pay off your credit
                        card is with a <strong> monthly direct debit</strong>.
                      </Text>
                      <br />
                      <Text>
                        You can choose whether to pay a set amount, the monthly
                        minimum or the full closing balance, and on your due
                        date each month your payment will automatically happen!
                      </Text>
                      <br />
                      <Text>
                        <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/6027991389329-Direct-Debit-Cards">
                          Click here to find out more about setting up a direct
                          debit.
                        </Link>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            // tabContentMinHeight="385px"
            css={`
              && {
                .payment-options-tabs__content {
                  padding: 32px;
                }
              }
            `}
          />
        </Section>

        <Box.Section
          id="faq"
          css={`
            background-color: ${COLOR.WHITE};
            @media (min-width: ${BREAKPOINT.LG}) {
              width: 50%;
            }
          `}
        >
          <div data-contentful={state?.faqData?.[0]?.contentfulID}>
            <Heading4
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              paddingBottom={MARGIN.M64}
            >
              {state?.faqData?.[0]?.title || 'Frequently asked questions'}
            </Heading4>
            <AnalyticsLocationProvider
              location={
                state?.faqData?.[0]?.title || 'frequently asked questions'
              }
            >
              <Accordion
                css={`
                  .accordion__item-body {
                    padding: 16px 24px;
                    background-color: #f8f8f8 !important;
                  }
                `}
                titleBgColor="grey-light"
                items={state?.faqData?.[0]?.data || PageData.faq}
              />
            </AnalyticsLocationProvider>
            <Link
              button={BUTTON_STYLE.TERTIARY}
              css="width: 100%; margin: 64px auto; width: 320px"
              target='_self'
              href={
                state?.faqData?.[0]?.cta?.url ||
                '/credit-cards/28-degrees/faqs/'
              }
              data-trackid="see-all-faq"
              trackEventData={{
                label: state?.faqData?.[0]?.cta?.text || 'See all FAQ'
              }}
            >
              {state?.faqData?.[0]?.cta?.text || 'See all FAQ'}
            </Link>
          </div>
        </Box.Section>

        {state?.importantInfoData?.[0] && (
          <ImportantInformation
            id="important-Info"
            {...state?.importantInfoData?.[0]}
          />
        )}
      </main>
    </Layout>
  );
};

export default TwoFeeWaiverPage;
